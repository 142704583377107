
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[shorthand]/bestellung",
      function () {
        return require("private-next-pages/[shorthand]/bestellung.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[shorthand]/bestellung"])
      });
    }
  